import { MarketingApi } from '../MarketingApi'
import { submitAndRoute } from '../concierge'
import { Optional, Options, PardotEvent } from '../types'
import { parseClassNames } from './utils'

const log = (msg: any, options?: Optional<Options, 'form'>) => {
  if (typeof options?.debug !== 'undefined' ? options.debug : true) {
    // eslint-disable-next-line no-console
    console.log(msg)
  }
}

export const deployPardotFormHandlerOnThankYouPage = (options: Optional<Options, 'form'>) => {
  const leadValues: Record<string, string> = {}
  const uri = decodeURIComponent(document.location.search.replace(/\+/g, ' '))
  const urlParams = new URLSearchParams(uri)
  const entries = urlParams.entries()
  let valid = false
  for (const [key, value] of entries) {
    if (key.toLowerCase().includes('email') && value.includes('@')) {
      leadValues[key] = value.replaceAll(' ', '+') // fix spaces for emails
      valid = true
    } else {
      leadValues[key] = value
    }
  }
  if (valid) {
    const marketingApi = new MarketingApi({
      ...options,
      domain: leadValues['CPTenantDomain'],
      router: leadValues['CPTenantRouter'],
      lead: {
        ...leadValues,
        ...(options.lead ?? {}),
      },
      map: true,
    })
    submitAndRoute({ ...marketingApi.options }, marketingApi)

    log(leadValues, options)
  }
}
// Get Lead object from form fields

export const getLeadObject = (domain: string, router: string, form: HTMLFormElement) => {
  const data = {
    CPTenantDomain: domain,
    CPTenantRouter: router,
  } as Record<string, string>

  // Fill lead object
  for (let i = 0, elem; (elem = form.elements[i++] as HTMLInputElement); ) {
    const fieldName = parseClassNames(elem.parentElement?.className) || elem.id
    if (elem.type.includes('submit') || elem.type.includes('fieldset')) {
      continue
    }
    if (elem.type.includes('select')) {
      const selectElem = elem as unknown as HTMLSelectElement
      // Save to lead obj

      if (selectElem.selectedIndex === 0) {
        data[elem.id] = '[not provided]'
      } else {
        data[elem.id] = selectElem.options[selectElem.selectedIndex].text
      }
    } else if (elem.type === 'hidden' || elem.parentElement?.className.includes('hidden')) {
      // Save to lead obj
      data[elem.id] = elem.value ? elem.value : '[not provided]'
    } else if (elem.type.includes('radio') && elem.checked === true) {
      data[elem.id] = elem.value
    } else if (elem.type.includes('checkbox')) {
      data[elem.id] = String(elem.checked) || '[not provided]'
    } else if (!elem.type.includes('radio')) {
      data[elem.id] = elem.value || '[not provided]'
    }
    // Supports both classnames and ids on pardot
    if (data[elem.id] && fieldName) {
      data[fieldName] = data[elem.id]
    }
  }
  // Log data and labels for debugging
  log(data)
  return data
}

export const deployPardotOnLookAndFeel = (options: Optional<Options, 'form'>) => {
  const formId = options.formId ?? '#pardot-form'
  // Submit button click handler
  const submitHandler = (form: HTMLFormElement) => () => {
    // Add URL parameters to form action
    const params = new URLSearchParams(
      getLeadObject(options.domain, options.router, form)
    ).toString()
    form.action = `${form.action}?${params}`
    log(form.action, options)
  }
  // Retry adding the form event listener
  const findFormAndAddListener = (count = 1) => {
    const form = document.querySelector(formId) as HTMLFormElement
    if (form) {
      form.addEventListener('submit', submitHandler(form))
      return
    }
    if (
      (typeof options.retryFormSearch !== 'undefined' ? options.retryFormSearch : true) &&
      count < 10
    ) {
      setTimeout(() => {
        findFormAndAddListener(count + 1)
      }, 1000)
    } else {
      log('no form found on this page, id used - ' + formId, options)
    }
  }
  findFormAndAddListener()
}

export const deployPardotIframeOnThankYouPage = () => {
  window.parent.postMessage(
    {
      message: 'PARDOT_FORM_SUCCESS',
      data: window.location.search,
    },
    '*'
  )
}

export const deployPardotIframeOnParentPage = (options: Optional<Options, 'form'>) => {
  const marketingApi = new MarketingApi(options)
  // Below is the event listener that will listen for the Pardot Events
  const receiveMessage = (event: PardotEvent) => {
    // Form was submitted and validated, call ChiliPiper
    if (event.data && event.data.message === 'PARDOT_FORM_SUCCESS') {
      const leadValues = {} as Record<string, string>
      const uri = decodeURIComponent(event.data.data.replace(/\+/g, ' '))
      const urlParams = new URLSearchParams(uri)
      const entries = urlParams.entries()
      for (const [key, value] of entries) {
        leadValues[key] = value
      }
      submitAndRoute(
        {
          domain: leadValues['CPTenantDomain'],
          router: leadValues['CPTenantRouter'],
          lead: {
            ...leadValues,
            ...(options.lead ?? {}),
          },
        },
        marketingApi
      )
    }
  }
  window.addEventListener('message', receiveMessage, false)
}
